
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  apiOrderDetail,
  apiOrderDeliver,
  apiFactoryLists,
  apiOrderLogistics
} from '@/api/shop'
import { apiExpressLists } from '@/api/setting'
@Component
export default class OrderLogistics extends Vue {
  @Prop({ default: '5vh' }) top!: string | number // 弹窗的距离顶部位置
  @Prop({ default: '0' }) id!: string | number // 订单ID
  @Prop({ default: true }) flag!: boolean // 是发货还是物流查询 true为发货 ，false为物流查询
  @Prop({ default: '' }) isShow!: string
  @Prop() orderType: number | undefined
  /** S Data **/
  visible = false // 是否

  fullscreenLoading = false // 加载方式

  // 物流订单信息
  orderData: any = {
    traces: {},
    delivery: {},
    address: {}
  }

  expressList = []
  factoryList: any[] = []
  express_name = ''

  // 发货表单
  form: any = {
    send_type: 1, //	是	int	配送方式:1-快递配送;2-无需快递
    express_id: '', //	是(配送方式为1时必选)	int	订单id
    invoice_no: '', //	是(配送方式为1时必选)	int	订单id
    remark: '', //	否	varchar	发货备注
    express_name: '',
    factory_id: ''
  }

  /** E Data **/

  /** S Method **/

  // 获取订单信息 flag 为 true的时候执行
  getOrderDeliveryInfo () {
    apiOrderDetail({ id: this.id }).then(res => {
      this.orderData = res
      this.fullscreenLoading = false
      this.getExpressList()
      this.getFactoryLists()
    })
  }

  // 获取物流查询
  getOrderLogistics () {
    apiOrderLogistics({ order_goods_id: this.id }).then(res => {
      this.orderData = res
      this.fullscreenLoading = false
    })
  }

  // 获取快递
  getExpressList () {
    apiExpressLists({}).then(res => {
      this.expressList = res
    })
  }

  // 工厂管理列表
  getFactoryLists () {
    apiFactoryLists({}).then(res => {
      this.factoryList = res.lists
    })
  }

  rendName (e: any) {
    this.expressList.forEach((item:any) => {
      if (item.id == e) {
        this.form.express_name = item.name
      }
    })
  }

  // 发货
  orderDelivery () {
    apiOrderDeliver({
      id: this.id,
      ...this.form
    }).then(res => {
      this.$emit('update', '')
      // this.getOrderLogistics()
    })
  }

  // 点击取消
  handleEvent (type: 'cancel' | 'confirm') {
    if (type === 'cancel') {
      this.close()
    }
    if (type === 'confirm') {
      if (this.flag) {
        // 虚拟发货
        if (this.form.send_type == 1) {
          if (this.form.express_id == '') {
            return this.$message.error('请选择快递公司')
          }
          if (this.form.invoice_no == '') {
            return this.$message.error('请填写快递单号')
          }
          if (this.form.factory_id == '') {
            return this.$message.error('请选择工厂')
          }
        }
      }

      this.orderDelivery()
      this.close()
    }
  }

  // 打开弹窗
  onTrigger () {
    this.fullscreenLoading = true
    this.flag == true ? this.getOrderDeliveryInfo() : this.getOrderLogistics()
    this.visible = true
  }

  // 关闭弹窗
  close () {
    this.visible = false
  }

  // @Watch('isShow')
  // changeShow(value: any) {
  //
  // }
}
